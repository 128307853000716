<template>
  <div v-if="loading">
    <a-loader class="w-14 h-14 block mx-auto" :color="'text-gray-300'"/>
  </div>
  <div v-else>
    <div class="flex flex-col items-center" :class="{ 'pb-44': isAuthenticated, 'pb-28': !isAuthenticated }">
      <div class="pt-4 mb-2 mx-auto">
        <div>
          <a-initials
            :name="values.name"
            :src="values.profile_picture"
            :desing-class="'h-20 w-20 block mx-auto'"
            @click="$router.replace({ name: 'Login' })"/>
        </div>
        <h1 class="text-black" style="font-size: 20px;">{{ '@' + values?.ig_username }}</h1>
        <!-- <span> Reserva en los últimos restaurantes que he visitado</span> -->
      </div>
<!--      <div class="pb-2">-->
<!--        <h2 class="text-black px-8">-->
<!--          Descubre los últimos sitios que he publicado. <b>¡Reserva aquí!</b>-->
<!--        </h2>-->
<!--      </div>-->
      <ul class="w-full py-2 space-y-3 max-w-sm px-8 md:px-2">
        <public-sololink-cell
          v-for="(link, key) in links"
          :key="key" :sololink="link"
          :isEditing="isEditing"
          @reservation="reserveModal"
          @remove="removeSololink(key)" />
      </ul>
      <div class="mt-normal" v-if="sololinks.length > 4">
        <a href="#" class="mx-auto text-primary" @click="more = !more">
          {{ more ? 'Ocultar' : 'Ver todos' }}
        </a>

      </div>
<!--      <div class="fixed text-center mx-auto w-full bg-white py-2 bottom-24">-->

<!--      </div>-->
      <div class="fixed text-center mx-auto w-full bg-white py-2 bottom-14">
        <button class="btn_send_primary mt-4 disabled:bg-gray" :class="[{'bg-green-600': copied === true, 'bg-SFRed': copied === false, 'bg-primary': copied === null}]" :disabled="isEditing" @click="doCopy()">
          <span>{{ copied ? 'Enlace copiado' : 'Compartir' }}</span>
        </button>
        <button class="btn_send_primary mt-4 disabled:bg-gray" @click="isEditing = !isEditing; copied = null" :class="{'bg-SFRed': isEditing, 'bg-primary': !isEditing}">
          <span>Editar</span>
        </button>
      </div>
    </div>
  </div>
  <booking
    solo-link
    v-if="selectedLink?.restaurant?.covermanager_id !== null"
    v-model:show="modal"
    v-model:comment="form.comment"
    :restaurant="selectedLink?.restaurant"
    :loading-reservation="loadingBooking"
    :reservation-form="{
        date: bookingFormValues.date,
        people_amount: bookingFormValues.people_amount,
        comment: form.comment,
        time: bookingFormValues.time,
        zone: bookingFormValues.zone
    }"
    :completed="completed"
    @validated-date="setReservationValues"
    @confirm-booking="reservation"
  />
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido crear la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveSuccess = false"
    :show="reserveSuccess">
    <h2 class="">
      ¡Enhorabuena!
    </h2>
    <p class="text-lg">
      Su reserva ha sido recibida. En breve recibirá confirmación vía email
    </p>
  </a-alert>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useArray, useErrors, useRepository, useSession } from '../../../hooks'
import PublicSololinkCell from './public-sololink-cell'
import { useRouter } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import Booking from '@/components/booking/booking-card'

export default {
  name: 'edit',
  components: {
    PublicSololinkCell,
    Booking
  },
  setup () {
    const getUserByIGUsername = useRepository(({ users }) => users.igUsername)
    const { user, isAuthenticated } = useSession()
    const router = useRouter
    const [sololinks, { set: setSololinks, remove: removeSololink }] = useArray()
    const more = ref(false)
    const loading = ref(false)
    const loadingBooking = ref(false)
    const linkError = ref(false)
    const values = ref({})
    const isEditing = ref(false)
    const copied = ref(null)
    // booking
    const bookingHours = ref([])
    const type = ref('')
    const selectedLink = ref(null)
    const setReservation = useRepository(({ bookings }) => bookings.sololinks)
    const people = ref(0)
    const selectedHours = ref('00:00')
    const selectedMinutes = ref('00')
    const bookingProvider = ref(false)
    const date = ref(null)
    const form = reactive({ date: '', comment: '', solo_link_id: '' })
    const client = reactive({ first_name: '', last_name: '', email: '', phone: '' })
    const peopleAmount = ref(2)
    const peopleLength = ref(0)
    const dateList = ref([])
    const completed = ref(false)
    const reserveSuccess = ref(false)
    const modal = ref(false)
    const reserveError = ref(false)
    const reserveErrorMessage = ref(false)

    const bookingFormValues = ref({})
    const bookingValues = ref(false)

    const fetch = () => {
      loading.value = !loading.value
      loadingBooking.value = true
      getUserByIGUsername(user.ig_username)
        .then(({ data }) => {
          if (data.type !== 'foodie') {
            return router.push({ name: 'Login' })
          }
          linkError.value = false
          values.value = data.object

          setSololinks(data.solo_links.sort((a, b) => a.created_at > b.created_at ? 1 : -1))
        })
        .catch(() => {
          linkError.value = true
        })
        .finally(() => {
          loading.value = !loading.value
          loadingBooking.value = false
        })
    }

    const reservation = (event) => {
      bookingProvider.value = selectedLink.value.restaurant.covermanager_id !== null

      const newForm = {
        date: bookingFormValues.value.date,
        comment: event.comment,
        solo_link_id: selectedLink.value.id,
        use_booking_provider: bookingProvider.value,
        people_amount: parseInt(bookingFormValues.value.people_amount),
        zone: bookingFormValues.value.zone,
        client: {
          first_name: event.first_name,
          last_name: event.last_name,
          email: event.email,
          phone: '+34' + event.phone.replace(/\D/g, '')
        }
      }
      loadingBooking.value = true
      setReservation(selectedLink.value.id)
        .store({ ...newForm })
        .then(() => {
          reserveSuccess.value = true
        })
        .finally(() => {
          loadingBooking.value = false
          modal.value = false
          completed.value = true
        })
        .catch(({ response }) => {
          reserveError.value = true
          modal.value = false
          date.value = ''
          reserveErrorMessage.value = useErrors(response?.data?.error_code)
        })
    }

    const reserveModal = (soloLink) => {
      if (!isEditing.value) {
        modal.value = true
        selectedLink.value = soloLink
        // selectedHours.value = selectedLink?.value?.slim_restaurant?.covermanager_id ? '00:00' : '00'
      }
    }

    const links = computed(() => (more.value ? sololinks.value : sololinks.value.slice(0, 4)))

    const doCopy = () => {
      copied.value = null
      const invitationRoute = window.location.origin + '/' + user.ig_username
      copyText(invitationRoute.toString(), undefined, (error, event) => {
        if (error) {
          copied.value = false
        } else {
          copied.value = true
        }
      })
    }

    const setReservationValues = (values) => {
      bookingFormValues.value = values
      bookingValues.value = true
    }

    onMounted(() => {
      fetch()
    })
    return {
      links,
      linkError,
      values,
      loading,
      loadingBooking,
      isAuthenticated,
      sololinks,
      isEditing,
      copied,
      bookingHours,
      type,
      selectedLink,
      setReservation,
      people,
      selectedHours,
      selectedMinutes,
      bookingProvider,
      date,
      form,
      more,
      client,
      peopleAmount,
      peopleLength,
      dateList,
      completed,
      bookingFormValues,
      bookingValues,
      reserveErrorMessage,
      reserveSuccess,
      reserveError,
      modal,
      doCopy,
      removeSololink,
      reserveModal,
      reservation,
      setReservationValues
    }
  }
}
</script>

<style scoped>
.btn_send_primary{
  display:inline-block;
  color:white;
  height: 44px;
  width: 255px;
  border-radius: 44px;
}
</style>
